:root {
  --blue: #4142bc;
  --white: #e9e9e9;
  --noto: "Noto Sans", sans-serif;
  --source: "Source Sans Pro", sans-serif;
}

h1 {
  font-size: 4rem;
  color: var(--white);
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}
h2 {
  font-size: 2em;
  color: var(--white);
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}
h4 {
  font-size: 1.2em;
  color: var(--white);
  font-family: "Noto Sans", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}
a {
  text-decoration: none;
}
p {
  color: var(--white);
  line-height: 1.3;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: var(--source);
  font-size: calc(16px + 7 * ((100vh - 320px) / 680));
}
.App-background-1 {
  position: fixed;
  z-index: -3;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--white);
}

.Header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  width: 100%;
}
.Header-container {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: max(450px, 60vw);
  transition-duration: 1s;
}
.Header-link {
  cursor: pointer;
  width: 100% + 2.5vh;
  height: 6vh;
  transition-duration: 1s;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
}
.Header-link:hover {
  font-size: 1.3rem;
}

.Navigator {
  background-color: var(--blue);
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  left: 93%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: min(100px, 5vw);
  border-radius: 50px;
  transition-duration: 2s;
}
.NavigatorButton-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(90px, 3.5vw);
  height: min(90px, 3.5vw);
}

.Intro {
  position: fixed;
  top: 5vh;
  left: 50%;
  z-index: -2;
}
.Intro-container {
  position: relative;
  left: -50%;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  grid-template-columns: 70% 30%;
  grid-template-rows: repeat(10, 10%);
  width: max(650px, 70vw);
  height: 30vh;
  text-align: left;
  transition-duration: 1s;
}
.Intro-name {
  padding: 0.5vh;
  grid-row-start: 1;
  grid-row-end: 4;
  vertical-align: middle;
  color: var(--blue);
}
.Intro-position {
  padding: 0.5vh;
  grid-row-start: 4;
  color: var(--blue);
}
.Intro-profile-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 11;
  margin: auto;
}
.Intro-profile {
  border-radius: 250px;
  width: 20em;
}
.Intro-portfolio-button {
  grid-row-start: 9;
}
.Intro-socials {
  grid-row-start: 6;
}
.Intro-portfolio-button-text {
  font-size: 1rem;
}

.Main-section-one {
  background-color: var(--white);
}
.Main-section-two {
  background-color: var(--blue);
}

.About {
  margin: auto;
  margin-top: 131vh;
  margin-bottom: 20vh;
  width: max(800px, 80vw);
  display: grid;
  grid-template-columns: 30% 70%;
  transition-duration: 0.7s;
}
.About-title {
  margin: auto;
  grid-row-start: 1;
  grid-row-end: 3;
}
.About-text-container {
  grid-column-start: 2;
}
.About-text {
  text-align: justify;
  text-align-last: center;
  text-shadow: 1px 2px black;
}

.Portfolio {
  margin-top: 20vh;
}
.Portfolio-background {
  position: fixed;
  z-index: -3;
  background-image: linear-gradient(to bottom, #8742bc, 10%, var(--blue));
  top: 0;
  height: 100vh;
  width: 100vw;
}
.Portfolio-projects {
  height: 100%;
  width: 100%;
  padding-bottom: 20vh;
}

.ProjectCard-wrapper {
  margin: auto;
  margin-top: max(40px, 4.5vh);
  display: grid;
  width: max(800px, 65vw);
  height: max(350px, 50vh);
  border-radius: 20px;
}
.ProjectCard {
  margin: auto;
  display: grid;
  width: max(800px, 65vw);
  height: max(350px, 50vh);
  grid-template-columns: repeat(50, 2%);
  grid-template-rows: repeat(50, 2%);
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
}
.ProjectCard:hover {
  background-color: rgba(255, 255, 255, 0.12);
}
.ProjectCard-name-wrapper {
  grid-row: 3 / 29;
  grid-column: 2 / 31;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProjectCard-name {
  margin: auto;
}
.ProjectCard-image {
  grid-row: 3 / 29;
  grid-column: 31 / 50;
  height: 100%;
  max-width: 100%;
}
.ProjectCard-description {
  font-size: 1.1em;
  grid-row: 32 / 50;
  height: 100%;
  grid-column: 2 / 50;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: center;
}
.ProjectCard-description::-webkit-scrollbar-thumb {
  background-color: #b8bfc29f;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.ProjectCard-description::-webkit-scrollbar-track {
  background-color: transparent;
}
.ProjectCard-description::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.ProjectCard-description::-webkit-scrollbar {
  width: 20px;
}

.Loading {
  width: 100vw;
  height: 100vh;
  display: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
}
.Loading-circle {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.Resume {
  margin-top: 7vh;
  height: min(800px, 90vh);
  width: max(900px, 60vw);
}
.Resume-selector {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
}

.background {
  position: absolute;
  top: 55vh;
  left: 0;
  width: 100%;
  z-index: -1;
}

.glass {
  border: none;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  color: white;
  text-transform: none;
}

.fade-in {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media screen and (max-width: 767px) {
}
